
<template>
  <div>
    <TopBar />
    <main class="main-slot page-content">
      <div class="page-width">
        <div class="copy">
          <div class="section-num">Section 4</div>
          <h1>Omnichannel Orchestration & Engagement</h1>
          <p>
            Successfully engaging customers is the sole path to revenue and
            loyalty. Orchestration is a brand’s effort to help customers through
            their omnichannel journeys to their destination — their conversion.
            Campaigns reach out to customers, while orchestration goes further,
            subtly helping customers by smoothing their paths.
          </p>
          <br><br>
          <img src="@/assets/omnichannel-mobile.png" class="mobile" alt="" />

          <form>
            <h2>
              Section 4: Omnichannel Orchestration & Engagement Self-Assessment
            </h2>
            <p>Select the answer that most accurately describes your brand.</p>
            <h3 class="question">
              How well does your brand orchestrate and manage customer journeys
              today?
            </h3>
            <ul>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_4_q1"
                  name="section_4_q1"
                  value="1"
                  checked="section_4_q1 === 1"
                /><label
                  >Not well at all. While we excel at batch email marketing
                  campaigns, we are just beginning to explore how to orchestrate
                  journeys.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_4_q1"
                  name="section_4_q1"
                  value="2"
                /><label
                  >We're getting there. We orchestrate the most important
                  customer journeys across channels, and have begun to develop
                  orchestration strategies that merge our channel silos.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_4_q1"
                  name="section_4_q1"
                  value="3"
                /><label
                  >We’ve got this. We harmonize and orchestrate engagement
                  activities across all channels. Our Customer Journey
                  Management system guides orchestration, spanning former silos
                  and supporting brand strategies.
                </label>
              </li>
            </ul>
            <h3 class="question">
              To what extent is your ability to orchestrate connected to and
              supported by your system of engagement—the set of applications you
              use to execute campaigns and journeys?
            </h3>
            <ul>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_4_q2"
                  name="section_4_q2"
                  value="1"
                  checked="section_4_q2 === 1"
                /><label
                  >Not at all. There is no connection between our engagement
                  applications, hence our ability to orchestrate journeys is
                  very limited.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_4_q2"
                  name="section_4_q2"
                  value="2"
                /><label
                  >Somewhat. We have invested in ‘re-wiring’ the backend to
                  enable orchestration, but our downstream apps are somewhat
                  outdated and siloed.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_4_q2"
                  name="section_4_q2"
                  value="3"
                /><label
                  >Feeling good. We have the process and tools to map out
                  orchestrated journeys, combined with connected engagement
                  tools that allow us to execute and deliver connected and
                  personalized experiences as a result.
                </label>
              </li>
            </ul>
            <h3 class="question">
              How is your brand leveraging Artificial Intelligence (AI) as part
              of your technology stack for orchestrating personalized
              omnichannel journeys?
            </h3>
            <ul>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_4_q3"
                  name="section_4_q3"
                  value="1"
                  checked="section_4_q3 === 1"
                /><label
                  >Not currently using. We are still somewhat skeptical and
                  trying to determine if AI plays a role in our customer
                  engagement/journey strategy.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_4_q3"
                  name="section_4_q3"
                  value="2"
                /><label
                  >Interested and exploring. We are piloting various
                  initiatives, such as subject line and send time optimization.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_4_q3"
                  name="section_4_q3"
                  value="3"
                /><label
                  >Deeply committed. Our brand leverages AI for capabilities
                  like personalized offers and to optimize for next best
                  experience across channels.
                </label>
              </li>
            </ul>
          </form>
        </div>
        <!-- .copy -->
        <nav class="sidebar-nav">
          <img src="@/assets/omnichannel.png" alt="" class="desktop" width="693" />
          <img src="@/assets/progress-05.svg" class="mobile progress" alt="" />

          <ul class="nav">
            <li>
              <router-link class="back" to="/content">Back</router-link>
            </li>
            <li>
              <router-link class="next" to="/insights">Next</router-link>
            </li>
          </ul>
        </nav>
        <!-- .sidebar-nav -->
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Omnichannel",
  components: {
    TopBar,
    Footer,
  },
  data() {
    return {
      questions: {
        section_4_q1: 1,
        section_4_q2: 1,
        section_4_q3: 1,
      },
    };
  },
  methods: {
    onChange() {
      this.$rankings["omnichannel"] =
        Number(this.questions.section_4_q1) +
        Number(this.questions.section_4_q2) +
        Number(this.questions.section_4_q3);
      if (
        this.$rankings["omnichannel"] == 3 ||
        this.$rankings["omnichannel"] == 4
      ) {
        this.$rank["omnichannel"] = 1;
      } else if (
        this.$rankings["omnichannel"] == 5 ||
        this.$rankings["omnichannel"] == 6
      ) {
        this.$rank["omnichannel"] = 2;
      } else {
        this.$rank["omnichannel"] = 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$hl-color: #eb1000;

@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Regular.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Regular.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Regular.svg#6cd9048a14b0e2f8daead838196d8a9d")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Bold.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Bold.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Bold.svg#bb4fbb99c3a3d8d572bd881924533371")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Light.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Light.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Light.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Light.svg#37b1bad62db9b227511ca6c262f28acc")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Adobe Clean", Arial, Helvetica, sans-serif;
  color: #000;
  background: #fafafa;
}

.page-width {
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  position: relative;
}

.page-content {
  padding: 0 20px 20px 20px;
  height: auto;
  background-color: #fff;
  position: relative;
}

.copy {
  width: 848px;
  padding-top: 37px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    padding-top: 30px;
  }
  .section-num {
    color: $hl-color;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }
  h1 {
    font-size: 42px;
    font-weight: 400;
    color: #000;
    margin: 0;
    @media screen and (max-width: 1200px) {
      font-size: 28px;
    }
  }
  p {
    font-size: 21px;
    font-weight: 400;
    width: 740px;
    border-bottom: 1px solid #707070;
    padding: 10px 0 20px 0;
    margin: 0;
    @media screen and (max-width: 1200px) {
      font-size: 20px;
      line-height: 25px;
      width: auto;
    }
  }
  img.mobile {
    display: none;
    max-width: 100%;
    @media screen and (max-width: 1200px) {
      display: block;
    }
  }
  form {
    h2 {
      font-size: 24px;
      line-height: 27px;
      font-weight: 400;
      margin: 23px 0 0 0;
    }
    p {
      font-size: 18px;
      padding: 0;
      margin: 0;
      border-bottom: none;
    }
    h3.question {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px;
      @media screen and (max-width: 1200px) {
        margin-bottom: 20px;
      }
    }
    ul {
      font-size: 18px;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        vertical-align: top;
        margin-bottom: 15px;
        @media screen and (max-width: 1200px) {
          display: block;
        }
      }
      input {
        vertical-align: top;
        height: 20px;
      }
      label {
        color: #000;
        margin-left: 5px;
      }
    }
  }
}

.steps {
  margin-top: 40px;
  .step {
    background: #fafafa;
    color: #000;
    border: 1px solid $hl-color;
    border-radius: 10px;
    height: 129px;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 15px 0;
    .step-num {
      font-size: 56px;
      line-height: 129px;
      width: 250px;
      padding: 0 34px;
    }
    .step-description {
      font-weight: 700;
      height: 100%;
      width: 100%;
      display: flex;
      font-size: 18px;
      line-height: 22px;
      div {
        display: flex;
        align-items: center;
      }
    }
  }
}

.sidebar-nav {
  position: absolute;
  right: -85px;
  top: 0;
  background: #f5f5f5;
  width: 40%;
  height: 964px;
  img {
    position: absolute;
    bottom: 100px;
    left: 30px;
    &.mobile {
      display: none;
      position: relative;
      left: 0;
      bottom: 0;
      margin: 35px 0;
    }
  }
  ul.nav {
    list-style: none;
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 30px;
    display: flex;
    justify-content: space-between;
    width: 381px;
    li {
      height: 54px;
      line-height: 54px;
      a {
        display: inline-block;
        height: 33px;
        line-height: 33px;
        color: #8e8e8e;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        text-decoration: none;
        padding: 0 25px 0 36px;
        margin: 0 20px 0 0;
        border-radius: 17px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 8px;
          height: 11px;
          background: url(../assets/nav-arrow-small-back.svg);
          background-repeat: no-repeat;
          top: 11px;
        }
        &.back {
          background: #cacaca;
          &::before {
            left: 11px;
          }
        }
        &.next {
          background: $hl-color;
          padding: 0 36px 0 25px;
          color: #fff;
          &::before {
            right: 11px;
            background: url(../assets/nav-arrow-small-next.svg);
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    position: relative;
    right: 0;
    background: none;
    height: auto;
    img.desktop {
      display: none;
    }
    img.mobile {
      display: block;
      width: 100%;
    }
    ul.nav {
      position: relative;
      left: 0;
      bottom: 0;
      width: auto;
      margin: 0 !important;
      padding-left: 0;
      li {
        margin: 0;
        padding: 0;
      }
      a {
        margin: 0;
        height: 54px !important;
        width: 156px !important;
        padding: 0 !important;
        line-height: 54px !important;
        border-radius: 27px !important;
        font-size: 25px !important;
        &::before {
          width: 17px !important;
          height: 25px !important;
          right: 20px !important;
          top: 15px !important;
          background-size: cover !important;
        }
        &.back {
          &::before {
            left: 20px !important;
          }
        }
      }
    }
  }
}
</style>
