
<template>
  <div>
    <TopBar />
    <main class="main-slot page-content">
      <div class="page-width">
        <div class="copy">
          <div class="section-num">Section 1</div>
          <h1>Organizational Readiness</h1>
          <p>
            Succeeding at personalized customer journeys requires brands to
            become customer-first, not campaign-first. Mature from
            campaign-centric to journey-centric by organizing around perfecting
            the end-to-end customer experiences, supported by documented
            strategy, budget and metrics.
          </p>
          <img src="@/assets/readiness-mobile.png" class="mobile" alt="" />
          <form id="readiness">
            <h2>Section 1: Organizational Readiness Self Assessment</h2>
            <p>Select the answer that most accurately describes your brand.</p>
            <h3 class="question">
              Has leadership set a clear, specific, and shared vision for being
              a customer-centric organization?
            </h3>
            <ul>
              <li>
                <input
                  @change="onChange($event)"
                  v-model="questions.section_1_q1"
                  type="radio"
                  name="section_1_q1"
                  value="1"
                  checked="section_1_q1 === 1"
                /><label
                  >No. Leadership has not communicated a plan to put customer
                  experience at the center of the company’s efforts, in order to
                  support desirable customer journeys.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  v-model="questions.section_1_q1"
                  type="radio"
                  name="section_1_q1"
                  value="2"
                /><label
                  >Moving in the right direction. Leadership plans to invest in
                  technology and organizational teams around customer journey
                  KPIs.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  v-model="questions.section_1_q1"
                  type="radio"
                  name="section_1_q1"
                  value="3"
                /><label
                  >We got this. Leadership has a dedicated budget, cross-company
                  collaboration, and is measuring ROI for delivering and
                  improving personalized customer journeys on all channels.
                </label>
              </li>
            </ul>
            <h3 class="question">
              How is your brand organized to coordinate and manage
              customer-centric experiences and journeys?
            </h3>
            <ul>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_1_q2"
                  name="section_1_q2"
                  value="1"
                  checked="section_1_q2 === 1"
                /><label
                  >Our brand has fragmented marketing teams that focus on
                  campaigns, sometimes unintentionally creating friction or
                  conflict across a customer’s journey.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_1_q2"
                  name="section_1_q2"
                  value="2"
                /><label
                  >Our brand has cross-department/cross-product line
                  collaboration between marketing teams that help shift our
                  focus to customer journeys rather than individual campaigns.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_1_q2"
                  name="section_1_q2"
                  value="3"
                /><label
                  >We have complete buy-in across the organization to
                  collaborate in delivering end-to-end holistic customer
                  experiences and managing customer journeys.
                </label>
              </li>
            </ul>
            <h3 class="question">
              Has your brand adopted processes, practices, and technology that
              reflect journey lifecycles (e.g., buy/own/adopt/advocate/buy more,
              upgrade or renew)?
            </h3>
            <ul>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_1_q3"
                  name="section_1_q3"
                  value="1"
                  checked="section_1_q3 === 1"
                /><label
                  >No. We are still trying to understand what lifecycle
                  engagement means to our brand.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_1_q3"
                  name="section_1_q3"
                  value="2"
                /><label
                  >Somewhat. We have established some initial KPIs to improve
                  and connect cross-lifecycle engagement, with input and review
                  from stakeholders.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_1_q3"
                  name="section_1_q3"
                  value="3"
                /><label
                  >Yes. We have established ownership in the form of a COE
                  responsible for optimizing customer experiences and journeys
                  across all the teams involved, including marketing, customer
                  experience/service, and product managers.
                </label>
              </li>
            </ul>
          </form>
        </div>
        <!-- .copy -->
        <nav class="sidebar-nav">
          <img
            src="@/assets/readiness.png"
            class="desktop"
            alt=""
            width="892"
          />
          <img src="@/assets/progress-02.svg" class="mobile progress" alt="" />
          <ul class="nav">
            <li>
              <router-link class="back" to="/dimensions">Back</router-link>
            </li>
            <li>
              <router-link class="next" to="/data">Next</router-link>
            </li>
          </ul>
        </nav>
        <!-- .sidebar-nav -->
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Readiness",
  components: {
    TopBar,
    Footer,
  },
  data() {
    return {
      questions: {
        section_1_q1: 1,
        section_1_q2: 1,
        section_1_q3: 1,
      },
    };
  },
  methods: {
    onChange() {
      this.$rankings["readiness"] =
        Number(this.questions.section_1_q1) +
        Number(this.questions.section_1_q2) +
        Number(this.questions.section_1_q3);
      if (
        this.$rankings["readiness"] == 3 ||
        this.$rankings["readiness"] == 4
      ) {
        this.$rank["readiness"] = 1;
      } else if (
        this.$rankings["readiness"] == 5 ||
        this.$rankings["readiness"] == 6
      ) {
        this.$rank["readiness"] = 2;
      } else {
        this.$rank["readiness"] = 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Regular.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Regular.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Regular.svg#6cd9048a14b0e2f8daead838196d8a9d")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Bold.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Bold.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Bold.svg#bb4fbb99c3a3d8d572bd881924533371")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Light.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Light.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Light.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Light.svg#37b1bad62db9b227511ca6c262f28acc")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
}

$hl-color: #eb1000;

body {
  margin: 0;
  padding: 0;
  font-family: "Adobe Clean", Arial, Helvetica, sans-serif;
  color: #000;
  background: #fafafa;
  display: none;
  border: 1px solid red;
}

#app {
  position: relative;
  overflow-x: hidden !important;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.page-width {
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  position: relative;
}

.page-content {
  padding: 0 20px 20px 20px;
  height: auto;
  background-color: #fff;
  position: relative;
}

.copy {
  width: 848px;
  max-width: 66%;

  padding-top: 37px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    padding-top: 30px;
  }
  .section-num {
    color: $hl-color;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }
  h1 {
    font-size: 42px;
    font-weight: 400;
    color: #000;
    margin: 0;
    @media screen and (max-width: 800px) {
      font-size: 28px;
    }
  }
  p {
    font-size: 21px;
    font-weight: 400;
    width: 740px;
    max-width: 100%;
    border-bottom: 1px solid #707070;
    padding: 10px 0 20px 0;
    margin: 0;
    @media screen and (max-width: 800px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  img.mobile {
    display: none;
    max-width: 100%;
    @media screen and (max-width: 1200px) {
      display: block;
    }
  }
  form {
    h2 {
      font-size: 24px;
      line-height: 27px;
      font-weight: 400;
      margin: 23px 0 0 0;
    }
    p {
      font-size: 18px;
      padding: 0;
      margin: 0;
      border-bottom: none;
    }
    h3.question {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px;
      @media screen and (max-width: 1200px) {
        margin-bottom: 20px;
      }
    }
    ul {
      font-size: 18px;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        vertical-align: top;
        margin-bottom: 15px;
        @media screen and (max-width: 1200px) {
          display: block;
        }
      }
      input {
        vertical-align: top;
        height: 20px;
        width: 20px;
      }
      label {
        color: #000;
        margin-left: 5px;
      }
    }
  }
}

.steps {
  margin-top: 40px;
  .step {
    background: #fafafa;
    color: #000;
    border: 1px solid $hl-color;
    border-radius: 10px;
    height: 129px;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 15px 0;
    .step-num {
      font-size: 56px;
      line-height: 129px;
      width: 250px;
      padding: 0 34px;
    }
    .step-description {
      font-weight: 700;
      height: 100%;
      width: 100%;
      display: flex;
      font-size: 18px;
      line-height: 22px;
      div {
        display: flex;
        align-items: center;
      }
    }
  }
}

.sidebar-nav {
  position: absolute;
  right: -85px;
  top: 0;
  background: #f5f5f5;
  width: 40%;
  height: 964px;
  img {
    position: absolute;
    bottom: 100px;
    right: 0;
    margin-right: -290px;
    width: 180%;
    max-width: 250%;
    &.mobile {
      display: none;
      position: relative;
      left: 0;
      bottom: 0;
      margin: 35px 0;
    }
  }
  ul.nav {
    list-style: none;
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 30px;
    display: flex;
    justify-content: space-between;
    width: 381px;
    li {
      height: 54px;
      line-height: 54px;
      a {
        display: inline-block;
        height: 33px;
        line-height: 33px;
        color: #000;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        text-decoration: none;
        padding: 0 25px 0 36px;
        margin: 0 20px 0 0;
        border-radius: 17px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 8px;
          height: 11px;
          background: url(../assets/nav-arrow-small-back.svg);
          background-repeat: no-repeat;
          top: 11px;
        }
        &.back {
          background: #cacaca;
          &::before {
            left: 11px;
          }
        }
        &.next {
          background: $hl-color;
          padding: 0 36px 0 25px;
          color: #fff;
          &::before {
            right: 11px;
            background: url(../assets/nav-arrow-small-next.svg);
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    position: relative;
    right: 0;
    background: none;
    height: auto;
    img.desktop {
      display: none;
    }
    img.mobile {
      display: block;
      width: 100%;
    }
    ul.nav {
      position: relative;
      left: 0;
      bottom: 0;
      width: auto;
      margin: 0 !important;
      padding-left: 0;
      li {
        margin: 0;
        padding: 0;
      }
      a {
        margin: 0;
        height: 54px !important;
        width: 156px !important;
        padding: 0 !important;
        line-height: 54px !important;
        border-radius: 27px !important;
        font-size: 25px !important;
        &::before {
          width: 17px !important;
          height: 25px !important;
          right: 20px !important;
          top: 15px !important;
          background-size: cover !important;
        }
        &.back {
          &::before {
            left: 20px !important;
          }
        }
      }
    }
  }
}
</style>
