
<template>
  <div>
    <TopBar />
    <main class="main-slot page-content">
      <div class="page-width">
        <div class="copy">
          <h1>Thank You!</h1>
          <h2>
            <span>Thank you for your submission.<br /></span>
            Click the link below to view your report.
          </h2>
          <img src="@/assets/thanks-mobile.png" alt="" class="mobile" />
          <a v-bind:href="this.$reportUrl['url']" class="cta" target="_blank">
            View your customer journey maturity report
          </a>
        </div>
        <!-- .copy -->
        <nav class="sidebar-nav">
          <img src="@/assets/thanks.png" alt="" width="954" />
        </nav>
        <!-- .sidebar-nav -->
      </div>
    </main>
    <Footer />
  </div>
</template>
  
  <script>
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Thanks",
  components: {
    TopBar,
    Footer,
  },
  data() {
    return {
      reportURL: this.$reportUrl.label,
    };
  },
  created() {
    console.log(this.$reportUrl["url"]);
  },
};
</script>
  
  <style lang="scss" scoped>
$hl-color: #eb1000;

@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Regular.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Regular.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Regular.svg#6cd9048a14b0e2f8daead838196d8a9d")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Bold.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Bold.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Bold.svg#bb4fbb99c3a3d8d572bd881924533371")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Light.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Light.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Light.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Light.svg#37b1bad62db9b227511ca6c262f28acc")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Adobe Clean", Arial, Helvetica, sans-serif;
  color: #000;
  background: #fafafa;
}

.page-width {
  width: 1300px;
  margin: 0 auto;
  text-align: left;
  position: relative;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.page-content {
  padding: 0 20px 20px 20px;
  height: auto;
  background-color: #fff;
  position: relative;
}

.copy {
  width: 848px;
  padding-top: 37px;
  min-height: 964px;
  h1 {
    font-size: 42px;
  }
  h2 {
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 40px;
  }
  img.mobile {
    max-width: 100%;
    margin-bottom: 50px;
    display: none;
  }
  .cta {
    display: inline-block;
    background: $hl-color;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    height: 54px;
    line-height: 54px;
    border-radius: 24px;
    padding: 0 24px;
    transition: 0.5s;
    &:hover {
      transform: scale(110%);
      box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.75);
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    padding-top: 15px;
    min-height: auto;
    h1 {
      font-size: 28px;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 20px;
      margin: 0;
      span {
        display: none;
      }
    }
    img.mobile {
      display: block;
    }
    .cta {
      height: auto;
      text-align: center;
      line-height: 26px;
      padding: 18px 24px;
      border-radius: 47px;
    }
  }
}

.sidebar-nav {
  position: absolute;
  right: -85px;
  top: 0;
  width: 496px;
  height: 964px;
  img {
    position: absolute;
    z-index: 0;
    top: 0;
    left: -390px;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
</style>
  