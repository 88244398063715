
<template>
  <div>
    <TopBar />
    <main class="main-slot page-content">
      <div class="page-width">
        <div class="copy">
          <h1>How well are you positioned for<br />your own journey?</h1>
          <h2>Measure Your Capabilities on 5 Dimensions</h2>
          <div class="dimensions">
            <div class="dimension">
              <div class="dimension-num">1</div>
              <div class="dimension-description">
                <div>
                  <h3>Organizational Readiness</h3>
                  <p>
                    Successful brands are moving from campaign-centric to
                    journey-centric approaches that place the customer at the
                    center of every interaction. They apply Customer Journey
                    Management strategies that flatten organizational silos and
                    disrupt the channel mindset, with the goal of facilitating
                    seamless and connected end-to-end experiences throughout the
                    customer lifecycle.
                  </p>
                </div>
              </div>
            </div>
            <!-- .dimension -->
            <div class="dimension">
              <div class="dimension-num">2</div>
              <div class="dimension-description">
                <div>
                  <h3>Customer Data Foundation</h3>
                  <p>
                    By unifying and syncing historical, contextual, behavioral
                    and relevant business data in a single customer profile,
                    brands can actively listen to real-time signals that offer
                    actionable insights and inform intent—creating the
                    conditions for personalized engagement across myriad
                    customer journeys. AI models can make use of real-time
                    customer data for audience generation.
                  </p>
                </div>
              </div>
            </div>
            <!--  dimension -->
            <div class="dimension">
              <div class="dimension-num">3</div>
              <div class="dimension-description">
                <div>
                  <h3>Content & Design</h3>
                  <p>
                    Brand assets are readily accessible within journey design
                    processes and workflows—encouraging collaboration, and
                    ensuring both brand consistency and faster deployment.
                    Increasingly, AI is utilized to ideate and generate the best
                    creative, as well as to automatically optimize assets for
                    delivery to any interaction touchpoint.
                  </p>
                </div>
              </div>
            </div>
            <!-- .dimension -->
            <div class="dimension">
              <div class="dimension-num">4</div>
              <div class="dimension-description">
                <div>
                  <h3>Omnichannel Orchestration & Engagement</h3>
                  <p>
                    Customer journeys are inherently omnichannel, making
                    engagement in the moments that matter challenging for many
                    brands. Customer Journey Management provides orchestration
                    strategies and tools that break down channel silos, allowing
                    brands to execute strategies wherever and however the
                    customer chooses to engage.
                  </p>
                </div>
              </div>
            </div>
            <!-- .dimension -->
            <div class="dimension">
              <div class="dimension-num">5</div>
              <div class="dimension-description">
                <div>
                  <h3>Insights & Experimentation</h3>
                  <p>
                    Analyze customer journeys as they unfold to anticipate and
                    respond to customer needs. Leverage real-time insights to
                    improve customer journeys, increase revenues/retention, and
                    reduce churn. AI aids and augments decisioning at scale, and
                    increasingly plays a role in journey optimization relative
                    to experience optimization and business outcomes.
                  </p>
                </div>
              </div>
            </div>
            <!-- .dimension -->
          </div>
        </div>
        <!-- .copy -->
        <nav class="sidebar-nav">
          <aside class="cartoon-balloon">
            <h3>Keep in Mind</h3>

            <p>
              Consider your priorities and initiatives for engaging and
              understanding your customers. Not just what you've been able to
              do, but what your strategy calls for.
            </p>
          </aside>
          <router-link class="begin" to="/readiness">Begin</router-link>
        </nav>
        <!-- .sidebar-nav -->
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Dimensions",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
$hl-color: #eb1000;

@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Regular.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Regular.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Regular.svg#6cd9048a14b0e2f8daead838196d8a9d")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Bold.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Bold.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Bold.svg#bb4fbb99c3a3d8d572bd881924533371")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Light.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Light.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Light.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Light.svg#37b1bad62db9b227511ca6c262f28acc")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Adobe Clean", Arial, Helvetica, sans-serif;
  color: #000;
  background: #fafafa;
}

.page-width {
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  position: relative;
}

.page-content {
  padding: 0 20px 0 20px;
  height: 1300px;
  background-color: #fff;
  position: relative;
  @media screen and (max-width: 1200px) {
    height: auto;
    padding-top: 20px;
    padding-bottom: 0;
  }
}

.copy {
  width: 811px;
  max-width: 70%;
  padding-top: 45px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    padding-top: 15px;
  }
  h1 {
    font-size: 42px;
    line-height: 53px;
    font-weight: 400;
    color: #000;
    margin: 0;
    @media screen and (max-width: 800px) {
      font-size: 28px;
      font-weight: 500;
      line-height: 140%;
    }
  }
  h2 {
    color: $hl-color;
    font-size: 34px;
    line-height: 42px;
    font-weight: 400;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 100%;
    @media screen and (max-width: 800px) {
      font-size: 41px;
      font-weight: 500;
      line-height: 120%;
    }
  }
}

.dimensions {
  margin-top: 20px;
  .dimension {
    background: #fafafa;
    color: #000;
    border: 1px solid $hl-color;
    border-radius: 10px;
    height: 180px;
    margin: 0 0 15px 0;
    position: relative;
    .dimension-num {
      font-size: 56px;
      line-height: 100px;
      text-align: center;
      width: 90px;
      height: 88px;
      border-radius: 50%;
      border: 2px solid #898989;
      position: absolute;
      left: 50px;
      top: 20px;
    }
    .dimension-description {
      font-weight: 700;
      height: 100%;
      width: 618px;
      font-size: 18px;
      line-height: 22px;
      position: absolute;
      top: 20px;
      left: 174px;
      h3 {
        font-size: 24px;
        color: #000;
        margin: 0 0 0 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 8px 0 0 0;
      }
    }
    @media screen and (max-width: 1200px) {
      height: auto;
      display: block;
      padding: 20px;
      .dimension-num {
        position: relative;
        left: 0;
        top: 0;
        padding: 0;
        margin: 0;
        color: #6e6e6e;
        font-weight: 500;
        line-height: 96px;
        display: block;
      }
      .dimension-description {
        position: relative;
        left: 0;
        top: 0;
        color: #6e6e6e;
        margin-top: 20px;
        width: auto;
        line-height: 140%;
      }
    }
  }
}

.sidebar-nav {
  position: absolute;
  right: -85px;
  top: 0;
  background: #f5f5f5;
  width: 496px;
  max-width: 50%;
  height: 964px;
  .cartoon-balloon {
    background: #fff;
    color: #000;
    border-radius: 30px;
    margin: 51px 80px 0 38px;
    padding: 33px 41px 75px 41px;
    position: relative;
    h3 {
      font-size: 32px;
      font-weight: 400;
      margin: 0;
    }
    p {
      font-size: 15px;
      line-height: 22px;
      margin: 5px 0 0 0;
    }
    &::after {
      content: "";
      width: 55.11px;
      height: 51.47px;
      position: absolute;
      bottom: -42px;
      right: 55px;
      background: url(../assets/cartoon-source.svg);
    }
  }
  a.begin {
    display: block;
    position: absolute;
    left: 38px;
    bottom: 90px;
    background: $hl-color;
    color: #fff;
    height: 54px;
    padding: 0 160px 0 143px;
    line-height: 54px;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 27px;
    &::before {
      content: "";
      position: absolute;
      width: 17px;
      height: 20px;
      background: url(../assets/nav-arrow.svg);
      background-repeat: no-repeat;
      top: 17px;
      right: 130px;
      transform: rotate(180deg);
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    position: relative;
    right: 0;
    height: auto;
    padding: 20px 0;
    margin-bottom: 0;
    .cartoon-balloon {
      padding: 20px;
      margin: 0 20px;
    }
    a.begin {
      position: relative;
      left: 0;
      bottom: 0;
      width: 322px !important;
      max-width: 90%;
      padding: 0 !important;
      margin: 70px auto 0 auto;
      font-size: 36px;
      height: 71px;
      line-height: 71px;
      border-radius: 35px;
      &::before {
        content: "";
        position: absolute;
        width: 17px;
        height: 20px;
        background: url(../assets/nav-arrow.svg);
        background-repeat: no-repeat;
        top: 28px;
        right: 25%;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
