
<template>
  <div>
    <TopBar />
    <main class="main-slot page-content">
      <div class="page-width">
        <div class="copy">
          <h1>Where are you taking me?</h1>
          <h2>Fifteen questions, then a personalized report.</h2>
          <img src="@/assets/slide-02.png" alt="" class="slide-02 mobile" />
          <div class="steps">
            <div class="step">
              <div class="step-num">Step 1</div>
              <div class="step-description">
                <div>
                  Answer 15 questions assessing your brand's Customer Journey
                  Management maturity
                </div>
              </div>
            </div>
            <!-- . step -->
            <div class="step">
              <div class="step-num">Step 2</div>
              <div class="step-description">
                <div>
                  Access your customized CJM Maturity Assessment Report to gain
                  new insights:
                  <br />
                  • Is your brand Evolving, Advancing, or Leading?<br />
                  • Learn your brand’s Strengths and Weaknesses<br />
                  • Get tips on how to advance
                </div>
              </div>
            </div>
            <!-- . step -->
            <div class="step">
              <div class="step-num">Step 3</div>
              <div class="step-description">
                <div>
                  Engage stakeholders from across your organization in your
                  brand’s journey to Customer Journey Management maturity
                </div>
              </div>
            </div>
            <!-- . step -->
          </div>
        </div>
        <!-- .copy -->
        <nav class="sidebar-nav">
          <img src="@/assets/slide-02.png" alt="" width="735" />
          <ul class="nav">
            <li><router-link class="back" to="/">Back</router-link></li>
            <li>
              <router-link class="next" to="/dimensions">Next</router-link>
            </li>
          </ul>
        </nav>
        <!-- .sidebar-nav -->
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Where",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Regular.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Regular.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Regular.svg#6cd9048a14b0e2f8daead838196d8a9d")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Bold.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Bold.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Bold.svg#bb4fbb99c3a3d8d572bd881924533371")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Light.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Light.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Light.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Light.svg#37b1bad62db9b227511ca6c262f28acc")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
}

$hl-color: #eb1000;

body {
  margin: 0;
  padding: 0;
  font-family: "Adobe Clean", Arial, Helvetica, sans-serif;
  color: #000;
  background: #fafafa;
}

.page-width {
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  position: relative;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.page-content {
  padding: 0 20px 0 20px;
  height: 964px;
  background-color: #fff;
  position: relative;
  @media screen and (max-width: 900px) {
    height: auto;
    padding-top: 20px;
    padding-bottom: 50px;
  }
}

.copy {
  width: 740px;
  max-width: 55%;

  padding-top: 52px;
  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 100%;
    padding-top: 15px;
  }
  h1 {
    font-size: 42px;
    font-weight: 400;
    color: #000;
    margin-top: 0;
    @media screen and (max-width: 800px) {
      font-size: 28px;
      font-weight: 500;
    }
  }
  h2 {
    color: $hl-color;
    font-size: 34px;
    line-height: 42px;
    font-weight: 400;
    margin: 0;
    @media screen and (max-width: 800px) {
      font-size: 41px;
      font-weight: 500;
      line-height: 120%;
    }
  }
  .slide-02 {
    display: none;
    max-width: 100%;
    @media screen and (max-width: 800px) {
      display: block;
    }
  }
}

.steps {
  margin-top: 40px;
  .step {
    background: #fafafa;
    color: #000;
    border: 1px solid $hl-color;
    border-radius: 10px;
    height: 129px;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 15px 0;
    .step-num {
      font-size: 56px;
      line-height: 129px;
      width: 250px;
      padding: 0 34px;
    }
    .step-description {
      font-weight: 700;
      height: 100%;
      width: 100%;
      display: flex;
      font-size: 18px;
      line-height: 22px;
      div {
        display: flex;
        align-items: center;
      }
    }
    @media screen and (max-width: 1300px) {
      height: auto;
      display: block;
      padding: 30px;
      .step-num {
        padding: 0;
        margin: 0;
        line-height: 100%;
        color: #8e8e8e;
        font-weight: 500;
      }
      .step-description {
        color: #6e6e6e;
        margin-top: 20px;
      }
    }
  }
}

.sidebar-nav {
  position: absolute;
  right: -85px;
  top: 0;
  background: #f5f5f5;
  width: 40%;
  height: 964px;
  img {
    position: absolute;
    top: 145px;
    left: -130px;
    max-width: 150%;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  ul.nav {
    list-style: none;
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 39px;
    li {
      display: inline-block;
      height: 54px;
      line-height: 54px;
      a {
        display: inline-block;
        height: 54px;
        line-height: 54px;
        color: #fff;
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        text-decoration: none;
        padding: 0 55px 0 77px;
        margin: 0 20px 0 0;
        border-radius: 27px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 17px;
          height: 20px;
          background: url(../assets/nav-arrow.svg);
          background-repeat: no-repeat;
          top: 17px;
        }
        &.back {
          background: #000;
          &::before {
            left: 45px;
          }
        }

        &.next {
          background: $hl-color;
          padding: 0 77px 0 55px;
          &::before {
            right: 45px !important;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 100%;
    position: relative;
    right: 0;
    background: none;
    height: auto;
    ul.nav {
      position: relative;
      bottom: 0;
      margin: 30px 0 0 0;
      padding: 0;
      display: flex;
      width: 100%;
      li {
        justify-content: space-between;
        display: block;
        width: 50%;
        a {
          padding: 0;
          width: 156px;
          margin-right: 0;
          &.back {
            padding: 0 0 0 5px;
            &::before {
              left: 30px !important;
            }
          }
          &.next {
            padding: 0 5px 0 0;
            &::before {
              right: 30px !important;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
</style>
