
<template>
  <div>
    <TopBar />
    <main class="main-slot page-content">
      <div class="page-width">
        <div class="copy">
          <div class="section-num">Section 2</div>
          <h1>Customer Data Foundation</h1>
          <p>
            By syncing multiple sources of data into a single view of the
            customer accessible to all departments, brands can successfully
            create engaging experiences for their customers in real-time. Data
            is unified, real-time, and connected to all systems of engagement.
          </p>
          <img src="@/assets/data-mobile.png" class="mobile" alt="" />

          <form>
            <h2>Section 2: Customer Data Foundation Self-Assessment:</h2>
            <p>Select the answer that most accurately describes your brand.</p>
            <h3 class="question">
              To what extent is your system of engagement built on a
              comprehensive, flexible, and real-time customer data foundation?
            </h3>
            <ul>
              <li>
                <input
                  @change="onChange($event)"
                  v-model="questions.section_2_q1"
                  type="radio"
                  name="section_2_q1"
                  value="1"
                  checked="section_2_q1 === 1"
                /><label
                  >It isn’t. Data is scattered and hard to access, making it
                  hard, if not impossible, to move beyond siloed single channel
                  communications.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  v-model="questions.section_2_q1"
                  type="radio"
                  name="section_2_q1"
                  value="2"
                /><label
                  >We’re getting there. Data is organized into databases,
                  warehouses and lakes, but separately managed and accessed by
                  different departments (e.g., marketing database, POS data,
                  CRM)
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_2_q1"
                  name="section_2_q1"
                  value="3"
                /><label
                  >We’re there. Marketers and engagement practitioners across
                  the organization have a common, comprehensive foundation on
                  which to execute omnichannel engagement.
                </label>
              </li>
            </ul>
            <h3 class="question">
              Is your brand able to use the data foundation to be flexible and
              comprehensive when engaging customers and audiences across myriad
              customer journeys?
            </h3>
            <ul>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_2_q2"
                  name="section_2_q2"
                  value="1"
                  checked="section_2_q2 === 1"
                /><label
                  >No. Engagement with customers/audiences is ad hoc due to
                  scattered data and uncoordinated teams within the brand.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_2_q2"
                  name="section_2_q2"
                  value="2"
                /><label
                  >Somewhat. A few specific action-triggered engagements can
                  simulate real-time (e.g., notification that package has been
                  shipped), but most engagements are independent of customer’s
                  current context because of data latency.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_2_q2"
                  name="section_2_q2"
                  value="3"
                /><label
                  >Yes. Since we have a centralized system, marketing teams have
                  the data to optimize for real-time engagement, responding to
                  customers in their moment.
                </label>
              </li>
            </ul>
            <h3 class="question">
              Is your brand nimble in connecting real-time data and coordinating
              actions across engagement channels, or is there friction that
              impedes timely and personalized execution?
            </h3>
            <ul>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_2_q3"
                  name="section_2_q3"
                  value="1"
                  checked="section_2_q3 === 1"
                /><label
                  >No. We can’t yet apply real-time data to customer engagement
                  tactics.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_2_q3"
                  name="section_2_q3"
                  value="2"
                /><label
                  >Somewhat. We have real-time data, but it is difficult to ac
                  upon it immediately because some systems—such as email and
                  mobile—are not connected.
                </label>
              </li>
              <li>
                <input
                  @change="onChange($event)"
                  type="radio"
                  v-model="questions.section_2_q3"
                  name="section_2_q3"
                  value="3"
                /><label
                  >Yes. Our CDP and our systems of delivery are totally
                  connected. Any data can be used immediately in any step of our
                  campaigns and journeys to deliver right-time experiences. Our
                  AI models use all data, including real-time data, to generate
                  audiences.
                </label>
              </li>
            </ul>
          </form>
        </div>
        <!-- .copy -->
        <nav class="sidebar-nav">
          <img src="@/assets/data.png" class="desktop" alt="" width="742" />
          <img src="@/assets/progress-03.svg" class="mobile progress" alt="" />
          <ul class="nav">
            <li>
              <router-link class="back" to="/readiness">Back</router-link>
            </li>
            <li>
              <router-link class="next" to="/content">Next</router-link>
            </li>
          </ul>
        </nav>
        <!-- .sidebar-nav -->
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Data",
  components: {
    TopBar,
    Footer,
  },
  data() {
    return {
      questions: {
        section_2_q1: 1,
        section_2_q2: 1,
        section_2_q3: 1,
      },
    };
  },
  methods: {
    onChange() {
      this.$rankings["data"] =
        Number(this.questions.section_2_q1) +
        Number(this.questions.section_2_q2) +
        Number(this.questions.section_2_q3);
      if (this.$rankings["data"] == 3 || this.$rankings["data"] == 4) {
        this.$rank["data"] = 1;
      } else if (this.$rankings["data"] == 5 || this.$rankings["data"] == 6) {
        this.$rank["data"] = 2;
      } else {
        this.$rank["data"] = 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$hl-color: #eb1000;

@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Regular.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Regular.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Regular.svg#6cd9048a14b0e2f8daead838196d8a9d")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Bold.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Bold.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Bold.svg#bb4fbb99c3a3d8d572bd881924533371")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Light.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Light.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Light.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Light.svg#37b1bad62db9b227511ca6c262f28acc")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Adobe Clean", Arial, Helvetica, sans-serif;
  color: #000;
  background: #fafafa;
}

.page-width {
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  position: relative;
}

.page-content {
  padding: 0 20px 20px 20px;
  height: auto;
  background-color: #fff;
  position: relative;
}

.copy {
  width: 848px;
  padding-top: 37px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    padding-top: 30px;
  }
  .section-num {
    color: $hl-color;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }
  h1 {
    font-size: 42px;
    font-weight: 400;
    color: #000;
    margin: 0;
    @media screen and (max-width: 1200px) {
      font-size: 28px;
    }
  }
  p {
    font-size: 21px;
    font-weight: 400;
    width: 740px;
    border-bottom: 1px solid #707070;
    padding: 10px 0 20px 0;
    margin: 0;
    @media screen and (max-width: 1200px) {
      font-size: 20px;
      line-height: 25px;
      width: auto;
    }
  }
  img.mobile {
    display: none;
    max-width: 100%;
    @media screen and (max-width: 1200px) {
      display: block;
    }
  }
  form {
    h2 {
      font-size: 24px;
      line-height: 27px;
      font-weight: 400;
      margin: 23px 0 0 0;
    }
    p {
      font-size: 18px;
      padding: 0;
      margin: 0;
      border-bottom: none;
    }
    h3.question {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px;
      @media screen and (max-width: 1200px) {
        margin-bottom: 20px;
      }
    }
    ul {
      font-size: 18px;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        vertical-align: top;
        margin-bottom: 15px;
        @media screen and (max-width: 1200px) {
          display: block;
        }
      }
      input {
        vertical-align: top;
        height: 20px;
      }
      label {
        color: #000;
        margin-left: 5px;
      }
    }
  }
}

.steps {
  margin-top: 40px;
  .step {
    background: #fafafa;
    color: #000;
    border: 1px solid $hl-color;
    border-radius: 10px;
    height: 129px;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 15px 0;
    .step-num {
      font-size: 56px;
      line-height: 129px;
      width: 250px;
      padding: 0 34px;
    }
    .step-description {
      font-weight: 700;
      height: 100%;
      width: 100%;
      display: flex;
      font-size: 18px;
      line-height: 22px;
      div {
        display: flex;
        align-items: center;
      }
    }
  }
}

.sidebar-nav {
  position: absolute;
  right: -85px;
  top: 0;
  background: #f5f5f5;
  width: 40%;
  height: 964px;
  img {
    position: absolute;
    bottom: 100px;
    left: -62px;
    &.mobile {
      display: none;
      position: relative;
      left: 0;
      bottom: 0;
      margin: 35px 0;
    }
  }
  ul.nav {
    list-style: none;
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 30px;
    display: flex;
    justify-content: space-between;
    width: 381px;
    li {
      height: 54px;
      line-height: 54px;
      a {
        display: inline-block;
        height: 33px;
        line-height: 33px;
        color: #8e8e8e;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        text-decoration: none;
        padding: 0 25px 0 36px;
        margin: 0 20px 0 0;
        border-radius: 17px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 8px;
          height: 11px;
          background: url(../assets/nav-arrow-small-back.svg);
          background-repeat: no-repeat;
          top: 11px;
        }
        &.back {
          background: #cacaca;
          &::before {
            left: 11px;
          }
        }
        &.next {
          background: $hl-color;
          padding: 0 36px 0 25px;
          color: #fff;
          &::before {
            right: 11px;
            background: url(../assets/nav-arrow-small-next.svg);
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    position: relative;
    right: 0;
    background: none;
    height: auto;
    img.desktop {
      display: none;
    }
    img.mobile {
      display: block;
      width: 100%;
    }
    ul.nav {
      position: relative;
      left: 0;
      bottom: 0;
      width: auto;
      margin: 0 !important;
      padding-left: 0;
      li {
        margin: 0;
        padding: 0;
      }
      a {
        margin: 0;
        height: 54px !important;
        width: 156px !important;
        padding: 0 !important;
        line-height: 54px !important;
        border-radius: 27px !important;
        font-size: 25px !important;
        &::before {
          width: 17px !important;
          height: 25px !important;
          right: 20px !important;
          top: 15px !important;
          background-size: cover !important;
        }
        &.back {
          &::before {
            left: 20px !important;
          }
        }
      }
    }
  }
}
</style>
