
<template>
  <div>
    <TopBar />
    <main class="main-slot page-content">
      <div class="page-width">
        <div class="copy">
          <h1>
            Congratulations!<br />
            You have completed your journey!
          </h1>
          <h2>
            Maturity Assessment:
            <em
              >Your responses demonstrate that your brand is
              <span>{{ this.$assessment }}</span> its maturity.</em
            >
          </h2>
          <p>
            Fill out this form to receive a report of your assessment with tips
            to improve your brand’s capabilities to create and deliver
            personalized customer journeys. An Adobe sales specialist will
            contact you in the next 72 hours to formulate a plan.
          </p>

          <form
            :action="appUrl"
            method="post"
            enctype="multipart/form-data"
            novalidate="true"
            @submit.prevent="startRun"
          >
          <!--
            <input
              type="hidden"
              name="report_url"
              v-model="report_url"
              :placeholder="this.$reportUrl['url']"
              />
          -->
            <div class="field">
              <input
                type="text"
                id="fname"
                name="fname"
                v-model="fname"
                required="required"
              />
              <label for="fname">First Name</label>
            </div>
            <div class="field">
              <input
                type="text"
                id="lname"
                name="lname"
                required="required"
                v-model="lname"
              />
              <label for="lname">Last Name</label>
            </div>
            <div class="field">
              <input type="text" id="title" name="title" required="required" v-model="title" />
              <label for="title">Job Title</label>
            </div>
            <div class="field">
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Phone Number"
                required="required"
                v-model="phone"
              />
            </div>
            <div class="field">
              <input
                type="email"
                id="biz_email"
                name="biz_email"
                required="required"
                v-model="biz_email"
              />
              <label for="biz_email">Business Email Address</label>
            </div>
            <div class="field">
              <input
                type="text"
                id="confirm_email"
                name="confirm_email"
                required="required"
                v-model="confirm_email"
              />
              <label for="confirm_email">Confirm Email Address</label>
            </div>
            <div class="field">
              <input
                type="text"
                id="company"
                name="company"
                required="required"
                v-model="company"
              />
              <label for="company">Company Name</label>
            </div>
            <div class="field">
              <select name="industry" required v-model="industry">
    <option selected value="Industry">Industry</option>
    <option>Advertising</option>
    <option>Agriculture and Forestry</option>
    <option>Construction</option>
    <option>Education - Higher Ed</option>
    <option>Education - K12</option>
    <option>Energy, Mining, Oil & Gas</option>
    <option>Financial Services</option>
    <option>Government - Federal</option>
    <option>Government - Local</option>
    <option>Government - Military</option>
    <option>Government - State</option>
    <option>Health Care</option>
    <option>Insurance</option>
    <option>Maunufacturing - Aerospace</option>
    <option>Maunufacturing - Automotive</option>
    <option>Maunufacturing - Consumer Goods</option>
    <option>Maunufacturing - Industrial</option>
    <option>Media & Entertainment</option>
    <option>Membership Organizations</option>
    <option>Non-Profit</option>
    <option>Pharmaceuticals & Biotech</option>
    <option>Professional & Technical Services</option>
    <option>Real Estate, Rental & Leasing</option>
    <option>Retail</option>
    <option>Technology Hardware</option>
    <option>Technology Software & Services</option>
    <option>Telecommunications</option>
    <option>Transportation & Warehousing</option>
    <option>Travel, Leisure & Hospitality</option>
    <option>Utilities</option>
  </select>

            </div>
            <div class="field"></div>
            <div class="field">
              <select name="country" required v-model="country">
    <option selected value="Country">Country</option>
    <option value="AF">Afghanistan</option>
    <option value="AX">Aland Islands</option>
    <option value="AL">Albania</option>
    <option value="DZ">Algeria</option>
    <option value="AS">American Samoa</option>
    <option value="AD">Andorra</option>
    <option value="AO">Angola</option>
    <option value="AI">Anguilla</option>
    <option value="AQ">Antarctica</option>
    <option value="AG">Antigua and Barbuda</option>
    <option value="AR">Argentina</option>
    <option value="AM">Armenia</option>
    <option value="AW">Aruba</option>
    <option value="AU">Australia</option>
    <option value="AT">Austria</option>
    <option value="AZ">Azerbaijan</option>
    <option value="BS">Bahamas</option>
    <option value="BH">Bahrain</option>
    <option value="BD">Bangladesh</option>
    <option value="BB">Barbados</option>
    <option value="BY">Belarus</option>
    <option value="BE">Belgium</option>
    <option value="BZ">Belize</option>
    <option value="BJ">Benin</option>
    <option value="BM">Bermuda</option>
    <option value="BT">Bhutan</option>
    <option value="BO">Bolivia</option>
    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
    <option value="BA">Bosnia and Herzegovina</option>
    <option value="BW">Botswana</option>
    <option value="BV">Bouvet Island</option>
    <option value="BR">Brazil</option>
    <option value="IO">British Indian Ocean Territory</option>
    <option value="BN">Brunei Darussalam</option>
    <option value="BG">Bulgaria</option>
    <option value="BF">Burkina Faso</option>
    <option value="BI">Burundi</option>
    <option value="KH">Cambodia</option>
    <option value="CM">Cameroon</option>
    <option value="CA">Canada</option>
    <option value="CV">Cape Verde</option>
    <option value="KY">Cayman Islands</option>
    <option value="CF">Central African Republic</option>
    <option value="TD">Chad</option>
    <option value="CL">Chile</option>
    <option value="CN">China</option>
    <option value="CX">Christmas Island</option>
    <option value="CC">Cocos (Keeling) Islands</option>
    <option value="CO">Colombia</option>
    <option value="KM">Comoros</option>
    <option value="CG">Congo</option>
    <option value="CD">Congo, Democratic Republic of the Congo</option>
    <option value="CK">Cook Islands</option>
    <option value="CR">Costa Rica</option>
    <option value="CI">Cote D'Ivoire</option>
    <option value="HR">Croatia</option>
    <option value="CU">Cuba</option>
    <option value="CW">Curacao</option>
    <option value="CY">Cyprus</option>
    <option value="CZ">Czech Republic</option>
    <option value="DK">Denmark</option>
    <option value="DJ">Djibouti</option>
    <option value="DM">Dominica</option>
    <option value="DO">Dominican Republic</option>
    <option value="EC">Ecuador</option>
    <option value="EG">Egypt</option>
    <option value="SV">El Salvador</option>
    <option value="GQ">Equatorial Guinea</option>
    <option value="ER">Eritrea</option>
    <option value="EE">Estonia</option>
    <option value="ET">Ethiopia</option>
    <option value="FK">Falkland Islands (Malvinas)</option>
    <option value="FO">Faroe Islands</option>
    <option value="FJ">Fiji</option>
    <option value="FI">Finland</option>
    <option value="FR">France</option>
    <option value="GF">French Guiana</option>
    <option value="PF">French Polynesia</option>
    <option value="TF">French Southern Territories</option>
    <option value="GA">Gabon</option>
    <option value="GM">Gambia</option>
    <option value="GE">Georgia</option>
    <option value="DE">Germany</option>
    <option value="GH">Ghana</option>
    <option value="GI">Gibraltar</option>
    <option value="GR">Greece</option>
    <option value="GL">Greenland</option>
    <option value="GD">Grenada</option>
    <option value="GP">Guadeloupe</option>
    <option value="GU">Guam</option>
    <option value="GT">Guatemala</option>
    <option value="GG">Guernsey</option>
    <option value="GN">Guinea</option>
    <option value="GW">Guinea-Bissau</option>
    <option value="GY">Guyana</option>
    <option value="HT">Haiti</option>
    <option value="HM">Heard Island and Mcdonald Islands</option>
    <option value="VA">Holy See (Vatican City State)</option>
    <option value="HN">Honduras</option>
    <option value="HK">Hong Kong</option>
    <option value="HU">Hungary</option>
    <option value="IS">Iceland</option>
    <option value="IN">India</option>
    <option value="ID">Indonesia</option>
    <option value="IR">Iran, Islamic Republic of</option>
    <option value="IQ">Iraq</option>
    <option value="IE">Ireland</option>
    <option value="IM">Isle of Man</option>
    <option value="IL">Israel</option>
    <option value="IT">Italy</option>
    <option value="JM">Jamaica</option>
    <option value="JP">Japan</option>
    <option value="JE">Jersey</option>
    <option value="JO">Jordan</option>
    <option value="KZ">Kazakhstan</option>
    <option value="KE">Kenya</option>
    <option value="KI">Kiribati</option>
    <option value="KP">Korea, Democratic People's Republic of</option>
    <option value="KR">Korea, Republic of</option>
    <option value="XK">Kosovo</option>
    <option value="KW">Kuwait</option>
    <option value="KG">Kyrgyzstan</option>
    <option value="LA">Lao People's Democratic Republic</option>
    <option value="LV">Latvia</option>
    <option value="LB">Lebanon</option>
    <option value="LS">Lesotho</option>
    <option value="LR">Liberia</option>
    <option value="LY">Libyan Arab Jamahiriya</option>
    <option value="LI">Liechtenstein</option>
    <option value="LT">Lithuania</option>
    <option value="LU">Luxembourg</option>
    <option value="MO">Macao</option>
    <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
    <option value="MG">Madagascar</option>
    <option value="MW">Malawi</option>
    <option value="MY">Malaysia</option>
    <option value="MV">Maldives</option>
    <option value="ML">Mali</option>
    <option value="MT">Malta</option>
    <option value="MH">Marshall Islands</option>
    <option value="MQ">Martinique</option>
    <option value="MR">Mauritania</option>
    <option value="MU">Mauritius</option>
    <option value="YT">Mayotte</option>
    <option value="MX">Mexico</option>
    <option value="FM">Micronesia, Federated States of</option>
    <option value="MD">Moldova, Republic of</option>
    <option value="MC">Monaco</option>
    <option value="MN">Mongolia</option>
    <option value="ME">Montenegro</option>
    <option value="MS">Montserrat</option>
    <option value="MA">Morocco</option>
    <option value="MZ">Mozambique</option>
    <option value="MM">Myanmar</option>
    <option value="NA">Namibia</option>
    <option value="NR">Nauru</option>
    <option value="NP">Nepal</option>
    <option value="NL">Netherlands</option>
    <option value="AN">Netherlands Antilles</option>
    <option value="NC">New Caledonia</option>
    <option value="NZ">New Zealand</option>
    <option value="NI">Nicaragua</option>
    <option value="NE">Niger</option>
    <option value="NG">Nigeria</option>
    <option value="NU">Niue</option>
    <option value="NF">Norfolk Island</option>
    <option value="MP">Northern Mariana Islands</option>
    <option value="NO">Norway</option>
    <option value="OM">Oman</option>
    <option value="PK">Pakistan</option>
    <option value="PW">Palau</option>
    <option value="PS">Palestinian Territory, Occupied</option>
    <option value="PA">Panama</option>
    <option value="PG">Papua New Guinea</option>
    <option value="PY">Paraguay</option>
    <option value="PE">Peru</option>
    <option value="PH">Philippines</option>
    <option value="PN">Pitcairn</option>
    <option value="PL">Poland</option>
    <option value="PT">Portugal</option>
    <option value="PR">Puerto Rico</option>
    <option value="QA">Qatar</option>
    <option value="RE">Reunion</option>
    <option value="RO">Romania</option>
    <option value="RU">Russian Federation</option>
    <option value="RW">Rwanda</option>
    <option value="BL">Saint Barthelemy</option>
    <option value="SH">Saint Helena</option>
    <option value="KN">Saint Kitts and Nevis</option>
    <option value="LC">Saint Lucia</option>
    <option value="MF">Saint Martin</option>
    <option value="PM">Saint Pierre and Miquelon</option>
    <option value="VC">Saint Vincent and the Grenadines</option>
    <option value="WS">Samoa</option>
    <option value="SM">San Marino</option>
    <option value="ST">Sao Tome and Principe</option>
    <option value="SA">Saudi Arabia</option>
    <option value="SN">Senegal</option>
    <option value="RS">Serbia</option>
    <option value="CS">Serbia and Montenegro</option>
    <option value="SC">Seychelles</option>
    <option value="SL">Sierra Leone</option>
    <option value="SG">Singapore</option>
    <option value="SX">Sint Maarten</option>
    <option value="SK">Slovakia</option>
    <option value="SI">Slovenia</option>
    <option value="SB">Solomon Islands</option>
    <option value="SO">Somalia</option>
    <option value="ZA">South Africa</option>
    <option value="GS">South Georgia and the South Sandwich Islands</option>
    <option value="SS">South Sudan</option>
    <option value="ES">Spain</option>
    <option value="LK">Sri Lanka</option>
    <option value="SD">Sudan</option>
    <option value="SR">Suriname</option>
    <option value="SJ">Svalbard and Jan Mayen</option>
    <option value="SZ">Swaziland</option>
    <option value="SE">Sweden</option>
    <option value="CH">Switzerland</option>
    <option value="SY">Syrian Arab Republic</option>
    <option value="TW">Taiwan, Province of China</option>
    <option value="TJ">Tajikistan</option>
    <option value="TZ">Tanzania, United Republic of</option>
    <option value="TH">Thailand</option>
    <option value="TL">Timor-Leste</option>
    <option value="TG">Togo</option>
    <option value="TK">Tokelau</option>
    <option value="TO">Tonga</option>
    <option value="TT">Trinidad and Tobago</option>
    <option value="TN">Tunisia</option>
    <option value="TR">Turkey</option>
    <option value="TM">Turkmenistan</option>
    <option value="TC">Turks and Caicos Islands</option>
    <option value="TV">Tuvalu</option>
    <option value="UG">Uganda</option>
    <option value="UA">Ukraine</option>
    <option value="AE">United Arab Emirates</option>
    <option value="GB">United Kingdom</option>
    <option value="US">United States</option>
    <option value="UM">United States Minor Outlying Islands</option>
    <option value="UY">Uruguay</option>
    <option value="UZ">Uzbekistan</option>
    <option value="VU">Vanuatu</option>
    <option value="VE">Venezuela</option>
    <option value="VN">Viet Nam</option>
    <option value="VG">Virgin Islands, British</option>
    <option value="VI">Virgin Islands, U.s.</option>
    <option value="WF">Wallis and Futuna</option>
    <option value="EH">Western Sahara</option>
    <option value="YE">Yemen</option>
    <option value="ZM">Zambia</option>
    <option value="ZW">Zimbabwe</option>
  </select>
              
            </div>

            <div class="field error">
              <p v-if="errors.length" style="color: red;">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" :key="error.id">{{ error }}</li>
                </ul>
              </p>
            </div>
            <div class="field">
              <button type="submit" class="submit" value="submit">Submit</button>
            </div>
          </form>
          <section id="what-to-do">
            <header>
              <h2>What To Do Next</h2>
            </header>

            <div id="steps">
              <div class="step">
                <div class="num">1</div>
                Review your maturity report, and spark a conversation with your
                brand’s stakeholders.
              </div>
              <div class="step">
                <div class="num">2</div>
                Learn more through reading our Thought Leadership, Customer
                Successes, and Adobe Resources.
              </div>
              <div class="step">
                <div class="num">3</div>
                Speak with a CJM specialist at Adobe to understand how we can support your business goals around personalized customer journeys.
              </div>
            </div>
          </section>
        </div>
        <!-- .copy -->
        <nav class="sidebar-nav">
          <aside class="cartoon-balloon">
            <h3>More on customer journeys</h3>

            <ul>
              <li>
                <a
                  href="https://business.adobe.com/content/dam/dx/us/en/products/journey-optimizer/adobe-journey-optimizer/related-content/pdf/customer-journeys-in-2021.pdf"
                  target="_blank"
                  >Customer Journeys: A 3-Part Strategy to Upping your Game</a
                >
              </li>

              <li>
                <a
                  href="https://business.adobe.com/blog/basics/customer-journey-builder"
                  target="_blank"
                  >Customer Journey Basics</a
                >
              </li>

              <li>
                <a
                  href="https://blog.adobe.com/en/publish/2021/09/14/unlocking-business-growth-with-customer-journey-management"
                  target="_blank"
                  >Unlocking Business Growth with Customer Journey Management</a
                >
              </li>
            </ul>
          </aside>
          <aside class="cartoon-balloon">
            <h3>Adobe Resources</h3>

            <ul>
              <li>
                <a
                  href="https://business.adobe.com/products/journey-optimizer/adobe-journey-optimizer.html"
                  target="_blank"
                  >Adobe Journey Optimizer
                </a>
              </li>

              <li>
                <a
                  href="https://business.adobe.com/resources/reports/forrester-wave-cross-channel-marketing-hubs-2023.html"
                  target="_blank"
                  >Adobe is a leader in the 2023 Forrester Wave: Cross-Channel Marketing Hubs
                </a>
              </li>
            </ul>
          </aside>
          <img src="@/assets/report.png" alt="" class="desktop" width="395" />
          <img src="@/assets/progress-07.svg" alt="" class="mobile" />

        </nav>
        <!-- .sidebar-nav -->
      </div>
    </main>
    <Footer />
  </div>
</template>
  
  <script>
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
//import Countries from "@/components/Countries.vue";
//import Industries from "@/components/Industries.vue";
import axios from "axios";
import router from "../router";

export default {
  name: "Report",
  data() {
    return {
      appUrl: "https://adobe-journey-report.viewstream-media.com/submit.php",
      fname: null,
      lname: null,
      title: null,
      phone: null,
      biz_email: null,
      confirm_email: null,
      company: null,
      industry: "Industry",
      country: "Country",
      errors: [],
    };
  },
  components: {
    TopBar,
    Footer,
    //    Countries,
    //    Industries,
  },
  created: function () {
    // How many points did our user log in this bad boy?
    var total_points = 0;
    total_points =
      this.$rankings["readiness"] +
      this.$rankings["data"] +
      this.$rankings["content"] +
      this.$rankings["omnichannel"] +
      this.$rankings["insights"];

    Object.entries(this.$rankings).forEach((entry) => {
      const [key, value] = entry;
      if (value == 3 || value == 4) {
        this.$rank[key] = 1;
      } else if (value == 5 || value == 6) {
        this.$rank[key] = 2;
      } else {
        this.$rank[key] = 3;
      }
      console.log(key + ":" + this.$rank[key]);
    });

    // What is their assessment?
    if (total_points < 22) {
      this.$assessment = "Evolving";
    } else if (total_points >= 23 && total_points <= 36) {
      this.$assessment = "Advancing";
    } else {
      this.$assessment = "Leading";
    }

    var assessmentLower = this.$assessment;

    // Finally, let's build the URL for their PDF report!
    this.$reportUrl["url"] =
      "https://adobe-journey-report.viewstream-media.com/report.php?readiness=" +
      this.$rank["readiness"] +
      "&data=" +
      this.$rank["data"] +
      "&content=" +
      this.$rank["content"] +
      "&insights=" +
      this.$rank["insights"] +
      "&omnichannel=" +
      this.$rank["omnichannel"] +
      "&rating=" +
      assessmentLower;

    console.log(this.$reportUrl);
  },
  methods: {
    startRun: function (e) {
      this.errors = [];
      if (
        this.fname &&
        this.lname &&
        this.title &&
        this.phone &&
        this.biz_email &&
        this.confirm_email == this.biz_email &&
        this.company &&
        this.industry != "Industry" &&
        this.country != "Country"
      ) {
        if (this.biz_email != this.confirm_email) {
          this.errors.push("Please make sure your email fields match.");
          e.preventDefault();
        }
        if (this.country == "Country") {
          this.errors.push("All fields required.");
          e.preventDefault();
        }
        if (this.industry == "Industry") {
          this.errors.push("All fields required.");
          e.preventDefault();
        }

        var post_data = {
          firstname: this.fname,
          lastname: this.lname,
          title: this.title,
          phone: this.phone,
          email: this.biz_email,
          company: this.company,
          industry: this.industry,
          country: this.country,
        };

        console.log(post_data);
        axios
          .post(
            "https://adobe-journey-report.viewstream-media.com/submit.php",
            {
              firstname: this.fname,
              lastname: this.lname,
              title: this.title,
              phone: this.phone,
              email: encodeURI(this.biz_email),
              company: this.company,
              industry: this.industry,
              country: this.country,
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then(function (response) {
            router.replace("thanks");
            console.log(response);
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        this.errors.push("All fields required.");
        this.errors.push("Please make sure your email fields match.");
        e.preventDefault();
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
$hl-color: #eb1000;

@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Regular.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Regular.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Regular.svg#6cd9048a14b0e2f8daead838196d8a9d")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Bold.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Bold.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Bold.svg#bb4fbb99c3a3d8d572bd881924533371")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Light.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Light.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Light.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Light.svg#37b1bad62db9b227511ca6c262f28acc")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Adobe Clean", Arial, Helvetica, sans-serif;
  color: #000;
  background: #fafafa;
  overflow: hidden;
}

.page-width {
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  position: relative;
}

.page-content {
  padding: 0 20px 0 20px;
  height: auto;
  background-color: #fff;
  position: relative;
}

.copy {
  width: 848px;
  padding-top: 37px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    padding-top: 30px;
  }
  .section-num {
    color: $hl-color;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }
  h1 {
    font-size: 42px;
    font-weight: 400;
    color: #000;
    margin: 0;
    @media screen and (max-width: 1200px) {
      font-size: 28px;
    }
  }
  h2 {
    font-size: 22px;
    font-weight: 200;
    margin: 20px 0;
    color: #000;
    em {
      color: $hl-color;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      span {
        text-decoration: underline;
      }
    }
    @media screen and (max-width: 1200px) {
      font-size: 24px;
      em {
        display: block;
        font-weight: 600;
        margin-top: 15px;
      }
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    width: 700px;
    padding: 10px 0 10px 0;
    margin: 0;
    color: #000;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }
  form {
    width: 640px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .field {
      width: 308px;
      min-height: 70px;
      &.error {
        ul {
          margin: 0;
        }
        p,
        li {
          color: $hl-color;
          font-size: 12px;
          white-space: pre-wrap;
        }
      }
      &.checkbox {
        text-align: right;
        margin-top: 15px;
      }
    }
    input,
    select {
      width: 308px;
      height: 39px;
      background: #fafafa;
      border: 1px solid #eaeaea;
      border-radius: 10px;
      padding: 0 0 0 10px;
      &::placeholder {
        color: #999;
        font-size: 18px;
      }
      option:disabled {
        color: #000;
      }
    }

    input[type="submit"] {
      width: auto;
    }

    input[required] + label {
      color: #999;
      font-family: Arial;
      font-size: 18px;
      position: relative;
      left: 10px;
      top: -32px;
      /* the negative of the input width */
    }

    input[required] + label:after {
      content: "*";
      color: red;
    }

    /* show the placeholder when input has no content (no content = invalid) */

    input[required]:invalid + label {
      display: inline-block;
    }

    /* hide the placeholder when input has some text typed in */

    input[required]:valid + label {
      display: none;
    }

    input[type="checkbox"] {
      width: 27px;
      height: 27px;
      margin-right: 5px;
      transform: translateY(25%);
    }

    button.submit {
      width: 308px;
      height: 44px;
      line-height: 44px;
      background: $hl-color !important;
      color: #fff;
      border: none;
      border-radius: 22px;
      font-size: 24px;
      font-weight: 700;
      margin: 17px 0 0 0;
      cursor: pointer;
    }
    @media screen and (max-width: 1200px) {
      display: block;
      .field {
        min-height: 70px;
        &:empty {
          min-height: 0;
        }
      }
    }
  }
}

#what-to-do {
  padding-bottom: 25px !important;
  h2 {
    font-size: 24px;
    font-weight: normal;
    color: #000;
  }
  #steps {
    display: flex;
    justify-content: space-between;
    width: 666px;
    .step {
      width: 28%;
      background: #fafafa;
      border: 1px solid #eaeaea;
      padding: 12px;
      font-size: 18px;
      color: #000;
      font-weight: normal;
      border-radius: 10px;
      .num {
        width: 60px;
        height: 60px;
        margin: 0 auto 12px auto;
        border: 2px solid #898989;
        border-radius: 50%;
        line-height: 60px;
        text-align: center;
        font-size: 47px;
        font-weight: normal;
        color: #000;
      }
    }
    @media screen and (max-width: 1200px) {
      display: block;
      width: 100%;
      .step {
        width: auto;
        margin-bottom: 24px;
        .num {
          margin: 0 0 5px 0;
        }
      }
    }
  }
}

.sidebar-nav {
  position: absolute;
  right: -85px;
  top: 0;
  background: #f5f5f5;
  width: 40%;
  height: 964px;
  .cartoon-balloon {
    background: #fff;
    color: #000;
    border-radius: 30px;
    margin: 51px 80px 0 38px;
    padding: 33px 41px 20px 41px;
    position: relative;
    h3 {
      font-size: 32px;
      line-height: 40px;
      font-weight: 400;
      margin: 0;
    }
    p {
      font-size: 15px;
      line-height: 22px;
      margin: 5px 0 0 0;
    }
    ul {
      list-style: none;
      margin: 20px 0 0 20px;
      padding: 0;
      li {
        margin: 0 0 20px 0;
        font-size: 17px;
        position: relative;
        &::before {
          display: block;
          content: "";
          height: 4px;
          width: 4px;
          border-radius: 50%;
          position: absolute;
          left: -16px;
          top: 10px;
          background: $hl-color;
        }
        a {
          color: #000000;
        }
      }
    }
    &::after {
      content: "";
      width: 55.11px;
      height: 51.47px;
      position: absolute;
      bottom: -42px;
      right: 55px;
      background: url(../assets/cartoon-source.svg);
    }
  }
  img {
    position: absolute;
    bottom: 100px;
    left: 50px;
    &.mobile {
      display: none;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    position: relative;
    right: 0;
    height: auto;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    .cartoon-balloon {
      width: auto;
      margin: 0 20px 80px 20px;
      padding: 15px;
    }
    img {
      position: relative;
      top: 0;
      left: 0;
      max-width: 100%;
      &.mobile {
        display: block;
        width: 85%;
        margin: 0 auto 20px auto;
      }
      &.desktop {
        display: none;
      }
    }
  }
}
</style>
  